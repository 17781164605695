class Utils {
  static formatAxiosError(error) {
    if (!error)
      return "Server Error";
    const data = error.response?.data;
    if (typeof data === 'object' && data !== null) {
      if (data.hasOwnProperty("detail")) {
        return data.detail;
      } else if (data.hasOwnProperty("message")) {
        return data.message;
      } else {
        return error.message;
      }
    } else if (typeof error === 'object' && error.hasOwnProperty('message')) {
      return error.message;
    } else {
      return error;
    }
  }
}

export default Utils;
