import React, {useState} from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import axios from "axios";
import jwtAuthService from "../services/jwtAuthService";
import {CircularProgress} from "@material-ui/core";
import Utils from "../Utils";

const UserSwitch = ({open = false, onClose}) => {
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const switchUser = () => {
    if (userId) {
      setLoading(true);
      document.cookie = 'token=; Max-Age=-99999999;';
      axios.post(`${process.env.REACT_APP_API_URL}/api/school/students/${userId}/login`)
        .then(
          response => {
            const {user, token} = response.data;
            jwtAuthService.setUser(user);
            jwtAuthService.setSession(token);
            window.location.reload();
          },
          error => {
            setError(Utils.formatAxiosError(error));
          })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  const handleKeyDown = (e) => {
    if (13 === e.keyCode) {
      switchUser();
    }
  }

  return <Dialog maxWidth={"lg"}
                 scroll={"body"}
                 open={open}
                 onEscapeKeyDown={onClose}>
    <DialogTitle>Сменить пользователя</DialogTitle>
    <DialogContent>
      <FormControl fullWidth={true} className={"mb-16"}>
        <TextField label={"Id пользователя"}
                   value={userId || ""}
                   onChange={e => setUserId(e.target.value)}
                   error={null !== error}
                   helperText={error ? error : null}
                   autoFocus={true}
                   onKeyDown={handleKeyDown}
        />
      </FormControl>
    </DialogContent>
    <DialogActions>
      <Button color={"secondary"}
              onClick={onClose}>Отмена</Button>
      <Button color={"primary"} onClick={switchUser}>
        {loading && <CircularProgress color={"inherit"} size={"1rem"} className={"mr-8"}/>}
        Войти
      </Button>
    </DialogActions>
  </Dialog>;
}

export default UserSwitch;
