import React from 'react';

const SimpleLoader = () => {
  return (<div className={"progress-loader"}>
    <div/>
    <div/>
    <div/>
    <div/>
  </div>)
}

export default SimpleLoader
