import {useState, useEffect}  from "react";
import axios from "axios";

export default function useExchange(){
    const [token, setToken] = useState(false);

    useEffect(() => {
        let apiToken = '';
        if(false === token) {
            axios.get(`/api/token/exchange/`,{ withCredentials: true})
                .then(
                    response => {
                        if (response.data && true === response.data.hasOwnProperty('token')) {
                            apiToken = response.data.token;
                            if (apiToken) {
                                localStorage.setItem('jwt_token', apiToken);
                                setToken(apiToken);
                            } else {
                                setToken(null);
                            }
                        }else{
                            setToken(null);
                        }
                    },
                    () => {
                        setToken(null);
                    })
                .catch(() => {
                    setToken(null)
                });
        }
    },[token]);

    return token;
}
