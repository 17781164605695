import axios from "axios";
import localStorageService from "./localStorageService";

class jwtAuthService {

  loginWithToken = () => {
    const token = localStorage.getItem('jwt_token');

    const headers = token ? {
      Authorization: "Token " + token,
    } : null;

    return axios.get(
      process.env.REACT_APP_API_URL + '/api/current_user/', {
        withCredentials: true,
        headers
      })
      .then(response => {
        this.setUser(response.data);
        this.setSession(token);
        return response.data;
      })
      .catch(error => {
        this.setSession(null);
        this.removeUser();
        return Promise.reject(error);
      });
  };


  logout = () => {
    return axios.post(process.env.REACT_APP_API_URL + '/api/logout/', null, {withCredentials: true})
      .then((response) => {
        this.setSession(null);
        this.removeUser();
        return response;
      });
  }

  setSession = token => {

    axios.interceptors.request.use(function (config) {
      const token = localStorage.getItem('jwt_token');
      let addCredentials = true;
      if (token) {
        if(!config.headers.hasOwnProperty('Authorization'))
        {
          config.headers.common['Authorization'] = 'Token ' + token
        }else{
          if(config.headers.Authorization === false)
          {
            delete config.headers.Authorization;
            addCredentials = false;
          }
        }
      }
      config.withCredentials = addCredentials;
      return config
    });

    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (401 === error.response?.status) {
        localStorage.removeItem("auth_user");
        localStorage.setItem("jwt_token", "");
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    });

    if (token) {
      localStorage.setItem("jwt_token", token);
    } else {
      localStorage.removeItem("jwt_token");
    }
  };

  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  }

  removeUser = () => {
    localStorage.removeItem("auth_user");
  }
}

export default new jwtAuthService();
